import React, { useState } from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"

export const pageQuery = graphql`
 query { 
    allStrapiSaleService {
        edges {
            node {
                seo {
                    metaTitle
                    metaDescription
                    shareImage {
                        url
                        localFile {
                            childImageSharp {
                            gatsbyImageData(width: 3840, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                            }
                        }
                    }
                },
                serviceContents {
                    title
                    info
                },
                serviceFeatures {
                    title
                    info
                    picture {
                        localFile {
                            childImageSharp {
                            gatsbyImageData(width: 2368, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                            }
                        }
                    }
                }
        }
     }
   } 
 }
`

export default function SaleService({ location,data }) {
    
    const [headerColor,setHeaderColor] = useState("bg-transparent text-black")
    const node = data.allStrapiSaleService.edges[0].node
    
    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="h-screen">
                <figure className="absolute top-0 left-0 w-full h-full"><GatsbyImage className="h-full" image={getImage(node.seo.shareImage.localFile)} alt="background"/></figure>
                <div className="container mx-auto pt-[193px] relative z-[1]">
                    <div className="flex justify-between items-end pb-[822px] sm:flex-col sm:items-start sm:pb-14">
                        <h1 className="text-[40px] leading-snug sm:mb-8">取得联系</h1>
                        <ul className="flex items-center text-white flex-wrap sm:w-full sm:justify-center">
                            <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/contact">联系方式</a></li>
                            <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/job">工作机会</a></li>
                            <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/supplier">供应商体系</a></li>
                            <li><a href="/sale-service" className="text-black">售后服务</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="bg-light">
                <div className="container mx-auto pt-[184px] pb-[275px] sm:pt-24 sm:pb-32">
                    <h2 className="text-[120px] leading-snug pb-10 text-gray-light sm:text-[100px]">{node.serviceContents[0].title}</h2>
                    <div className="flex justify-end">
                        <p className="text-[40px] leading-snug max-w-[944px] sm:text-3xl">{node.serviceContents[0].info}</p>
                    </div>
                </div>
            </div>
            <div className="pb-[140px] overflow-hidden sm:pb-20">
                    {
                        node.serviceFeatures.map((n,i)=>{
                            return <div key={i} className={`container mx-auto mt-[100px] xl:mt-16 min-h-[889px] xl:min-h-full relative flex xl:flex-col ${i%2!==0?'flex-row-reverse xl:flex-row-reverse':''}`}>
                                        <div className={`w-[430px] xl:w-full ${i%2!==0?"ml-auto":"mr-auto"}`}>
                                            <h2 className="text-[40px] leading-snug pb-5">{n.title}</h2>
                                            <p>{n.info}</p>
                                        </div>
                                        <GatsbyImage className={`w-[1184px] xl:w-full xl:!relative xl:mt-[36px] !absolute top-[0] ${i%2!==0?"right-[496px] xl:right-0":"left-[496px] xl:left-0"}`} image={getImage(n.picture.localFile)} alt="product"/>
                                    </div>
                        })
                    }
            </div>
            <div className="bg-light">
                <div className="container mx-auto pt-[164px] pb-[190px]">
                    <h2 className="text-[120px] leading-snug pb-10 text-gray-light sm:text-[100px]">{node.serviceContents[1].title}</h2>
                    <div className="flex justify-end">
                        <p className="text-[40px] leading-snug max-w-[944px] sm:text-3xl">{node.serviceContents[1].info}</p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

